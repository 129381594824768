import Avatar from 'react-avatar';
import ricky from './assets/ricky.png';
import seaira from './assets/seaira.png';
import jayla from './assets/jayla.png';
import zeke from './assets/zeke.png';

const profiles = [
  {
    name: 'ricky',
    avatar: ricky,
    link: 'https://fcsmnstry.io/4tt/jQz2l4Y5P0'
  },
  {
    name: 'seaira',
    avatar: seaira,
    link: 'https://fcsmnstry.io/4tt/Tbm647gNJc'
  },
  {
    name: 'jayla',
    avatar: jayla,
    link: 'https://fcsmnstry.io/4tt/xv1Jy0CfjV'
  },
  {
    name: 'zeke',
    avatar: zeke,
    link: 'https://fcsmnstry.io/4tt/4Kg0YlNmfZ'
  }
]


function App() {
  return (
    <>
      <video className='videoTag' autoPlay loop playsInline muted>
        <source src="https://assets.sonsofprophets.app/EastAfricaRevivalBG.mp4" type='video/mp4' />
      </video>

      <header className="viewport-header">
        <h1>
          &nbsp;Busia,Kenya
          <span>Gospel Crusade</span>

          <div className="profiles">
            {profiles.map((profile) => (
              <a className="avatarLink" href={profile.link} target="_blank" key={profile.name}>
                <Avatar size="100" round={true} src={profile.avatar} />
              </a>
            ))}
          </div>
            
          <div className="normal-text-bigger">
              Click on a photo to give
          </div>

          {/* <div className="normal-text">
          </div> */}
        </h1>
      
      </header>
    </>
  );
}

export default App;
